<template>
  <div class="main_container">
    <div class="row mb-3">
      <div class="text-left col-12">
        <router-link :to="'/Decaissement'">
          Décaissement>
        </router-link>
        <router-link :to="'/ordre-virements'">
          Ordres de virement>
        </router-link>
        <span>Détails ordre de virement</span>
      </div>
    </div>
    <h2 class="page_title text-center">Orde de virement : Détails</h2>
    <h3 class="text-center"> Ordre virement N° : {{virement.id}}</h3>
    <h4 class="text-center">Date : {{virement.date}}</h4>
    <div class="inner_container">
      <div class="tab_holder">
        <table class="table tablestyle_3 table-striped">
          <thead>
            <tr >
              <th scope="col"
                  style="padding-left:20px">
                Numéro
              </th>
              <th scope="col"
                  class="text-center">Date</th>
              <th scope="col"
                  class="text-center">Montant</th>

            </tr>
          </thead>
          <tbody>
            <tr v-for="(v,key) in virement.decaissements" 
                :key="key">

              <td>{{v.id}}</td>
              <td class="text-center">{{v.date}}</td>
              <td class="text-right">{{Math.round(Number(v.decompte.montant)).toLocaleString()}} FCFA</td>
                     
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<style>

</style>
<script>
import { mapActions, mapGetters } from "vuex"
export default {
  name: "DetailVirements",
  data: () => ({
    programmeData: {
      limite: 10,
      avant: "MQ=="
    }
  }),
  created() {
    this.getVirement({id_ordre_virement:this.$route.params.id})
  },
  computed: {
    ...mapGetters(["virement", "virementsErrors"])
  },
  methods: {
    ...mapActions(["getVirement"]),
  }
}
</script>
